import React, { Component } from "react"
import { Link } from "gatsby"
import { useEffect } from "react"

const LScript = ({ keyword }) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.id = "script2"
    script.type = "text/javascript"
    script.src = "https://epnt.ebay.com/static/epn-smart-tools.js"
    script.async = true
    document.getElementById("script2").appendChild(script)
  }, [])

  return (
    <>
      <div id="script2"></div>
      <p></p>
      <div className="ebayContainer">
        <div className="ebay">
          <ins
            className="epn-placement"
            data-config-id="5efca4bb33062f08c1484c2d"
            data-keyword={keyword}
          ></ins>
        </div>
      </div>
    </>
  )
}

export class searchbarc extends Component {
  constructor() {
    super()
    this.state = {
      topicBox: null,
      maxPrice: null,
      minPrice: null,
      sortBy: null,
      category: "search",
      show: false,
    }

    this.searchFunction = this.searchFunction.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleChange({ target }) {
    this.setState({
      [target.name]: target.value,
    })
  }

  handleClick({ target }) {
    this.setState({
      category: target.name,
    })
    console.log(target.name)
  }

  ebayFunction() {
    this.setState({
      show: false,
    })
  }

  searchFunction() {
    var priceFilter = ""
    if (this.state.maxPrice || this.state.minPrice) {
      priceFilter =
        "&minPrice=" + this.state.minPrice + "&maxPrice=" + this.state.maxPrice
    }
    var sort = "sortBy=BEST_MATCH"
    if (this.state.sortBy) {
      sort = "sortBy=" + this.state.sortBy
    }
    var category = ""
    if (this.state.category) {
      category = "category_id=" + this.state.category
    }
    const placeName = [
      "108061882547758",
      "boise",
      "sanfrancisco",
      "108013345886344",
      "dc",
      "mexicocity",
      "london",
      "edmonton",
      "ottawa",
      "111094862248685",
      "paris",
      "nagpur",
      "saopaulo",
      "singapore",
      "111711568847056",
      "bangkok",
      "cairo",
    ]

    if (this.state.topicBox) {
      var i
      for (i = 0; i < 17; i++) {
        window.open(
          "https://www.facebook.com/marketplace/" +
            placeName[i] +
            "/search" +
            "?" +
            category +
            "&deliveryMethod=local_pick_up&query=" +
            this.state.topicBox +
            priceFilter +
            "&radius=804&vertical=C2C&" +
            sort,
          i + "window"
        )
      }

      this.setState({
        show: true,
      })
    } else {
      window.open(
        "https://www.facebook.com/marketplace/106601109380094/search/?query=" +
          "Example: Porsche 911 // Return to searchworldmarketplace.com to search again" +
          priceFilter +
          "&latitude=37.2570976&longitude=-104.6693853&radiusKM=5000&vertical=C2C&" +
          sort +
          "G1window"
      )
      this.setState({
        show: true,
      })
    }
  }

  render() {
    const categories = [
      "vehicles",
      "bicycles",
      "classifieds",
      "apparel",
      "allelectronics",
      "entertainment",
      "family",
      "hobbies",
      "propertyforsale",
      "instruments",
      "propertyrentals",
    ]

    const categoryNames = [
      "Vehicles",
      "Bicycles",
      "Classifieds",
      "Apparel",
      "Electronics",
      "Entertainment",
      "Family",
      "Hobbies",
      "Property For Sale",
      "Instruments",
      "Property For Rent",
    ]

    const categoryList = categories.map((category, index) => (
      <button
        className="responsiveList"
        alt={category}
        name={category}
        value={category}
        onClick={this.handleClick}
        key={index}
        id={category}
        count={index}
      >
        <img
          className="icons"
          src={require(`../icons/${category}.png`)}
          alt={category}
          name={category}
          value={category}
        ></img>

        {categoryNames[index]}
      </button>
    ))

    return (
      <div>
        {this.state.show && <LScript keyword={this.state.topicBox} />}

        <div className="Nav1">
          <form
            className="nav"
            onSubmit={e => {
              e.preventDefault()
              this.searchFunction()
            }}
            onChange={e => {
              e.preventDefault()
              this.ebayFunction()
            }}
          >
            <input
              className="searchbar"
              size="20"
              placeholder="Allow pop-ups to view all results"
              name="topicBox"
              value={this.state.topicBox}
              onChange={this.handleChange}
            ></input>
            <button
              type="button"
              className="button2"
              onClick={this.searchFunction}
            >
              <i className="fa fa-search"></i>
            </button>
            <br />
          </form>

          <div className="nav2">
            <div className="boxes">
              <div className="boxesTitle">Price</div>
              <input
                className="sort"
                placeholder="min"
                name="minPrice"
                value={this.state.minPrice}
                onBlur={this.handleChange}
              ></input>
              <input
                className="sort"
                placeholder="max"
                name="maxPrice"
                value={this.state.maxPrice}
                onBlur={this.handleChange}
              ></input>
              <p></p>
              <div className="boxesTitle">Sort By</div>

              <select
                id="sort"
                name="sortBy"
                className="sort"
                value={this.state.sortBy}
                onBlur={this.handleChange}
              >
                <option value="BEST_MATCH">Recommended </option>
                <option value="PRICE_DESCEND">Price: Highest first</option>
                <option value="PRICE_ASCEND">Price: Lowest First</option>
                <option value="CREATION_TIME_DESCEND">
                  Date: Newest First
                </option>
                <option value="CREATION_TIME_ASCEND">Date: Oldest First</option>
              </select>

              <p></p>
              <div className="boxesTitle">Clear Filters: </div>
              <Link to="/home">
                <button className="sort"> Reset </button>
              </Link>
            </div>
            <div className="box2">
              <div className="boxesTitle">Category: {this.state.category}</div>
              <div>{categoryList}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default searchbarc
